@import "variables";
//
//// Setting the path to fonts files.
//$icon-font-path: "/static/node_modules/bootstrap-sass/assets/fonts/";
$iconic-font-path: "../node_modules/open-iconic/font/fonts/";

@import "../node_modules/open-iconic/font/css/open-iconic.scss";
//
@import "header";
@import "footer";


@import "frontpage";

html, body {
    /* The html and body elements cannot have any padding or margin. */
    height: 100%;
    margin: 0;
    color: $dark_blue;
}

body {
    font-family: 'Open Sans', sans-serif;
}

.background {
    background-color: #f6f7f6;
}

.detail-page {
    .ui.segment {
        font-weight: $font-light;
        border: none;
        margin-top: 10px;
        border-top: 2px solid lighten($navy, 20%);
        border-radius: unset;
        box-shadow: unset;
        margin-bottom: 1em;
        font-size: 1em !important;

        h1 {
            font-size: 1.8em;
            margin-top: 0.5em;
            margin-bottom: 0.5rem;
        }

        h2 {
            font-size: 1.6em;
            margin-top: 1em;
        }

        hr {
            border: none;
            border-top: 1px solid rgba(0,0,0,.1);
        }

        strong {
            font-weight: $font-heavy;
        }

        pre {
            overflow: auto;
            code {
                color: inherit;
            }
        }

        code {
            background-color: transparent;
        }

        a[name] {
            color: inherit;
        }

        .cite-paragraph {
            font-weight: $font-light;
            line-height: 1.2;
            margin-bottom: 30px;
        }

        .news-content {
            margin-top: 2em;
        }

        .definition-paragraph {
            padding-top: 1em;
        }
    }
}

.config-file-preview-modal {
    .config-content {
        white-space: pre-wrap;
    }
}

.visualise-events {
    margin-bottom: 10rem;

    h1 {
        font-size: 2em;
        margin-top: 1.5em;
        margin-bottom: 1rem;
        text-align: center;
    }

    h2 {
        font-size: 1.6em;
        margin-top: 0.5em;
        text-align: center;
    }

    .segment {
        text-align: center;
    }
}

.col-position {
    margin-top: 10px;
}

.content-style {
    font-size: 14px;
    color: #35495e;
}

@media only screen and (max-width: 992px) {
    .header-text-section {
        display: none;
    }

    #demo-logo {
        display: none;
    }
}

.header-text-section {
    position: absolute;
    bottom: 45vh;
    font-size: 0.9rem;
}

.header-links-section {
    position: absolute;
    bottom: 30px;
    right: 0;
    text-align: center;
}

.header-link {
    height: 180px;
}

.header-link.education {
    background: url('../../static/assets/img/top-education-link.svg') 50% 0% no-repeat;
    background-size: 200px;
    padding-top: 130px;
}

.header-link.education .btn-info {
    background-color: #e57e25;
    border: none;
    cursor: pointer;
}

.header-link.research .btn-info {
    background-color: #3b97d3;
    border: none;
    cursor: pointer;
}

.header-link.education .btn-info:hover {
    background-color: #d17322;
}

.header-link.research .btn-info:hover {
    background-color: #388ec7;
}

.header-link.research {
    background: url('../../static/assets/img/top-research-link.svg') 50% 0% no-repeat;
    background-size: 200px;
    padding-top: 130px;
}

@media only screen and (min-width: 992px) {
    .explore-menu {
        margin-left: -110%;
    }
}

@media only screen and (min-width: 992px) {
    .about-menu {
        margin-left: -20%;
    }
}

@media only screen and (min-width: 992px) {
    .mobile-search {
        display: none;
    }
}

#mobile-search-position {
    margin-top: -3%;
}

@media only screen and (max-width: 992px) {
    #main-search {
        display: none;
    }
}

#home-research {
    min-height: auto;
    background-color: white;
}

#news-card {
    min-height: 388.7px;
}

#news-card .card-body {
    min-height: 162px;
}

#news-card h4 {
    font-weight: bolder;
    font-size: 1.1em;
}

#visualise-home {
    min-height: auto;
}

.od-icon {
    min-height: 140px;
    padding: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.od-teaching {
    background-image: url('../../static/assets/img/teaching.svg');
    background-size: 23%;
}

.od-visualize {
    background-image: url('../../static/assets/img/visualize.svg');
    background-size: 20%;
}

#visualise-home h4 {
    font-weight: bolder;
    font-size: 1.4em;
}

.od-db-education {
    background-image: url('../../static/assets/img/db-education.svg');
    background-size: 30%;
}

.od-model {
    background-image: url('../../static/assets/img/model.svg');
    background-size: 30%;
}

.od-vm {
    background-image: url('../../static/assets/img/vm.svg');
    background-size: 60%;
}

.od-db-research {
    background-image: url('../../static/assets/img/db-research.svg');
    background-size: 30%;
}

.info-block {
    padding: 10px;
    max-width: 600px;
    min-width: 318px;
}

.info-block.education a {
    color: #e57e25;
}

.info-block.research a {
    color: #283747;
}

.info-block h4 {
    font-weight: bolder;
    font-size: 1.4em;
}

.info-block ul {
    list-style: none;
    text-align: left;
}

.info-block ul li {
    padding: 20px 0 5px 0;
}

.info-block ul li a {
    font-weight: bolder;
    font-size: 1.1em;
}

.dropdown-item {
    color: #3b97d3 !important;
    display: block;
    //padding: 3px 20px;
    line-height: 1.42857;
    white-space: nowrap;
}

.custom-card {
    min-height: 181px;
}


a {
    color: #2E588A;
}

.record-elem li .card {
    border: none;
    border-radius: unset;
}

.results-position {
    margin-top: 2%;
}

#date-published {
    color: grey;
}

// glossary
.record-elem .card {
    border: none;
    border-radius: unset;
}

.see-also {
    margin-right: 2px;
}

// breadcrumbs
.custom-breadcrumb {
    margin-bottom: 0;
}

.card-body {
    h4 {
        font-size: 20px;
    }

    a {
        color: $light-blue;
    }
}

.date-author-small {
    font-size: 0.6em;
}

.pagination.file-pagination {
    display: inline-block;
    border: 1px solid #ccc;

    li {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        border-right: 1px solid #ccc;

        &.active {
            background-color: #3b97d3;
            color: #fff;

            a {
                color: #fff;
            }
        }

        &.pagination-last {
            border-right: none;
        }
    }
    li:hover:not(.active) {background-color: #ddd;}
}

.list-nobullets {
    list-style: none;
    padding-left: 22px;
}

#loading-bar .bar{
    background-color: $loading-color;
    height: $loading-width;
}

// Labels

.ui.label {

    &.dark-cyan {
        background-color: $dark-cyan;
        color: white;

        &:hover {
            background-color: darken($dark-cyan, $darken-on-hover);
            color: white;
        }

    }

    &.dark-green {
        background-color: $dark-green;
        color: white;

        &:hover {
            background-color: darken($dark-green, $darken-on-hover);
            color: white;
        }
    }

    &.dark-blue {
        background-color: $dark-blue;
        color: white;

        &:hover {
            background-color: darken($dark-blue, $darken-on-hover);
            color: white;
        }
    }

    &.navy {
        background-color: $navy;
        color: white;

        &:hover {
            background-color: darken($navy, $darken-on-hover);
            color: white;
        }
    }
}

// End Labels

.clickable {
    cursor: pointer;
}

.search-btn {
    background-color: $light-blue;
    border-color: $light-blue;
    color: white;
}

.modal-backdrop {
    background-color: #ccc;
    opacity: 0.6;
}

.file-row {
    display: flex;
}

.files-table {
    display: flex;
    flex-direction: column;

    .files-table-header {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        border-top: 1px solid #ccc;
        border-bottom: 2px solid #ccc;

        > div {
            justify-content: start;
        }
    }


    .files-row {
        display: flex;
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        padding: 5px;

        > div {
            align-items: center;
            display: flex;
        }
    }
    .file-item-name {
        flex: 1;
        overflow: hidden;
        padding-right: 15px;

        > div {
            overflow: hidden;
        }
    }
    .file-item-size {
        width: 60px;
    }
    .file-item-actions {
        width: 155px;

        a.btn {
            font-size: 12px;
            padding: 5px;
        }
    }
}
